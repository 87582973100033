import {computed, defineComponent, onMounted, onBeforeUnmount, reactive, toRefs, watch, ref, nextTick} from "vue"
import AppLoader from "@/components/AppLoader.vue"
import {useStore} from "vuex"
import DataTable from "@/components/DataTable"
import {useDataTable} from "@/composables/useDataTable"
import DataPaging from "@/components/DataPaging"
import {useI18n} from "vue-i18n"
//import SelectorColumns from "@/components/SelectorColumns"
import SelectorColumns from "@/components/Popups/SelectorColumns.vue"
import Draggable from "vuedraggable"
//import {useResizePanels} from "@/composables/useResizePanels"
import {useScreenDocumentTitle} from "@/composables/useScreenDocumentTitle"
import SvgIcon from "@/components/SvgIcon"
import MenuTicket from "@/components/Menus/MenuTicket.vue"
import {IData} from "@/types"
import {onBeforeRouteLeave, useRouter} from "vue-router"
import {useTicketActions} from "@/composables/useTicketActions"
import VSelect from "@/components/Popups/VSelect.vue"
import {useFloatingExists} from "@/composables/useFloatingExists"
import TicketService from "@/services/ticket_service"

import TicketsToolbar from "@/components/TicketsToolbar"
import MenuScreen from "@/components/Menus/MenuScreen.vue"
import {useAppUtils} from "@/composables/useAppUtils"
//import ApiService from "@/services/api_service"
import {
  useFetchState,
  autorefreshBlockAdd,
  autorefreshBlockRemove,
  forceRefreshScreen
} from "@/composables/useFetchState"
import ContentPanels from "@/components/ContentPanels.vue"
import {useCurrentScreen} from "@/composables/useCurrentScreen"
import DashboardListviewRightPanel from "@/components/DashboardListviewRightPanel.vue"
import {useScrollKeep} from "@/composables/useScrollKeep"

export default defineComponent({
  name: "DashboardScreen",
  components: {
    AppLoader,
    DataTable,
    DataPaging,
    SelectorColumns,
    Draggable,
    SvgIcon,
    MenuTicket,
    VSelect,
    TicketsToolbar,
    MenuScreen,
    ContentPanels,
    DashboardListviewRightPanel,
  },
  props: {
    selectedItemId: {
      type: String,
      required: false
    },
    order: {
      type: String,
      required: false
    },
    reverse: {
      type: Number,
      required: false
    },
    pageCurrent: {
      type: Number,
      required: false
    },
    step: {
      type: String,
      required: false
    },
    search: {
      type: String,
      required: false
    },
    searchIn: {
      type: String,
      required: false
    },
    searchFields: {
      type: String,
      required: false
    },
    requestedById: {
      type: String,
      required: false
    },
    assignedToId: {
      type: String,
      required: false
    },
    serverParam: {
      type: String,
      required: false
    },
  },
  setup(props) {

    const store = useStore()
    const router = useRouter()
    const { t } = useI18n()

    const moduleName = 'dashboard'

    //const resizablePanel = 'left'

    const datatableItems = computed(() => store.getters[moduleName + '/tickets'])
    const isMultiSelectTickets = computed(() => store.getters['settings/getAll']['lw_multi-select'] == 1)

    //store.commit('addLoader', {to: '.screen-container', type: 'app-loader', opacity: 0.1})

    // const loadingsState = reactive({
    //   loadingFull: false, //!datatableItems.value.length,
    //   loadingLeft: false,
    //   loadingRight: false,
    // })

    const {
      isSuccessServerResponse,
      showResponseSuccessMessage,
      isMayContinue,
      navigateWithConfirm,
      hideStartLoader,
      showLeftPanelLoader,
      hideLeftPanelLoader,
      showRightPanelLoader,
      hideRightPanelLoader,
      showPanelsLoaders,
      hidePanelsLoaders,
      getScrollbarWidth,
      getAuthUser,
      getExpanderColumnWidth,
      getCurrentScreen,
    } = useAppUtils()

    if (datatableItems.value.length) {
      hidePanelsLoaders()
    }

    //const stepModel = ref(props.step)
    const archiveSelectItems = [
      { title: t('All <strong>Active</strong> tickets'), value: '0', id: '0'},
      { title: t('All <strong>Archived</strong> tickets'), value: 'archived', id: 'archived'},
      { title: t('All Tickets'), value: 'all', id: 'all'},
    ]
    const getStepModelValue = (stepValue) => archiveSelectItems.find(item => item.value === stepValue)

    const stepModel = ref(getStepModelValue(props.step))
    let stepModelWatchPaused = false

    watch(stepModel, async (step, oldStep) => {
      console.log('DashboardScreen watch stepModel', step?.value, oldStep?.value, !stepModelWatchPaused)
      if (stepModelWatchPaused) {
        return false
      }
      if (!await isMayContinue()) {
        stepModelWatchPaused = true
        stepModel.value = oldStep
        await nextTick()
        stepModelWatchPaused = false
        return false
      }
      showPanelsLoaders()
      await changeStateParams({step: step?.value, pageCurrent: 1, selectedItemId: null})
      await hidePanelsLoaders()
    })

    watch(() => props.step, async () => {
      stepModelWatchPaused = true
      stepModel.value = getStepModelValue(props.step)
      await nextTick()
      stepModelWatchPaused = false
    })

    const stepInStore = computed(() => store.getters[moduleName + '/step'])
    watch(stepInStore, async (value) => {
      stepModelWatchPaused = true
      stepModel.value = getStepModelValue(value)
      await nextTick()
      stepModelWatchPaused = false
    })

    // const livePreviewPanelsOnResize = ref(true)
    // const notesOffset = computed(() => store.getters[moduleName + '/notesOffset'] as number)
    // watch(notesOffset, value => {
    //   if (value > 1) {
    //     livePreviewPanelsOnResize.value = false
    //   }
    // })

    const notesPanelLoading = ref(false)
    // watch(() => props.selectedItemId, async (value) => {
    //   console.log('DashboardScreen watch selectedItemId', value)
    //   refNotesPanel.value?.resetData()
    // })

    console.log('DashboardScreen', store.getters[moduleName + '/step'])

    useCurrentScreen(moduleName)
    useScreenDocumentTitle(moduleName)

    const {
      fetchStateInit,
      fetchState,
      changeStateParams,
      getRouteObj,
      doAutorefresh,
      initAutorefresh,
      cancelAutorefresh,
    } = useFetchState({
      storeNamespace: moduleName,
    })

    const datatable = useDataTable( {
      storeNamespace: moduleName,
    })

    const {
      scrollToSelected,
      clickDatatableRowHandler,
      visibleFields,
    } = datatable

    const ticketsTableFields = computed(() => {
      const fields = [...visibleFields.value]
      if (isMultiSelectTickets.value) {
        fields.unshift({
          "id": "99999",
          "fieldname": "checkbox",
          "visible": "1",
          "width": "36",
          "orders": "1",
          "required": 0,
          "can_resize": 0,
          "can_move": 0,
          "label": "",
          "can_sort": 0,
          "align": "left"
        })
      }
      return fields
    })

    const leftPanelScrollContainer = ref()

    const {
      keepScroll,
      restoreScroll,
    } = useScrollKeep()


    const afterFetchStateInit = async () => {
      //loadingsState.loadingFull = false
      await nextTick()
      setTimeout(scrollToSelected, 400)
    }

    onMounted(async () => {
      console.log('DashboardScreen onMounted', JSON.stringify(props))
      restoreScroll(leftPanelScrollContainer, moduleName)
      try {
        await fetchStateInit(props)
        await afterFetchStateInit()
        initAutorefresh()
        await hidePanelsLoaders()
        hideStartLoader()
      } catch (e) {
        console.log('DashboardScreen onMounted error', e, JSON.stringify(props))
      }
    })

    onBeforeUnmount(() => {
      keepScroll(leftPanelScrollContainer, moduleName)
      cancelAutorefresh()
    })

    onBeforeRouteLeave(async () => {
      if (!await isMayContinue()) { return false }
    })


    const refTicketMenuButton = ref()
    const refEditColumnsButton = ref()
    const ticketMenuParams = ref<IData>({})
    const refTicketMenu = ref<InstanceType<typeof MenuTicket> | null>(null)
    const refScreenMenu = ref<InstanceType<typeof MenuScreen> | null>(null)
    const refScreenMenuTarget = ref()
    const refSelectorColumns = ref<InstanceType<typeof SelectorColumns> | null>(null)
    const tagsAlign = computed(() => store.getters['settings/tags_align'])

    const {isExist: isExistTicketMenu, triggerHandler: triggerHandlerTicketMenu} = useFloatingExists(refTicketMenu)
    const {isExist: isExistScreenMenu, triggerHandler: triggerHandlerScreenMenu} = useFloatingExists(refScreenMenu)
    const {isExist: isExistSelectorColumns, triggerHandler: triggerHandlerSelectorColumns} = useFloatingExists(refSelectorColumns)

    const {
      cloneTicket,
      archiveTicket,
      removeTicket,
      assignTicketToMe,
      moveTicketToNextStage,
    } = useTicketActions()


    watch(() => refTicketMenu.value?.isOpened, (newValue, oldValue) => {
      console.log('Dashboard watch refTicketMenu.value?.isOpened', newValue, oldValue)
      if (!newValue && oldValue !== undefined) {
        if (ticketMenuParams.value?.id) {
          store.commit(moduleName + '/updateTicket', {id: ticketMenuParams.value.id, attrs: {isActiveMenu: false}})
        }
        ticketMenuParams.value = {}
      }
    })

    const refRightPanel = ref<InstanceType<typeof DashboardListviewRightPanel> | null>(null)

    let loadingId = 0
    let ticketLoadingTimer = 0
    const tableRowClickHandler = async (id) => {
      console.log('Dashboard tableRowClickHandler', props.selectedItemId, id.toString(), props.selectedItemId !== id.toString())
      if (props.selectedItemId !== id.toString() && await isMayContinue()) {
        clearTimeout(ticketLoadingTimer)
        //store.commit(moduleName + '/set_selectedItemId', id.toString())
        document.querySelector('.tickets-table tr.selected')?.classList?.remove('selected')
        document.querySelector(`.tickets-table tr[data-id="${id}"]`)?.classList?.add('selected')
        autorefreshBlockAdd('Dashboard_tableRowClickHandler')
        loadingId = id
        notesPanelLoading.value = true
        showRightPanelLoader()
        refRightPanel.value?.resetData()
        ticketLoadingTimer = setTimeout(async () => {
          console.log('tableRowClickHandler notesPanelLoading.value = true', id, loadingId, performance.now())
          await clickDatatableRowHandler(id)
          console.log('tableRowClickHandler notesPanelLoading.value = false', id, loadingId, performance.now())
          if (loadingId === id) {
            notesPanelLoading.value = false
            await hideRightPanelLoader()
            autorefreshBlockRemove('Dashboard_tableRowClickHandler')
            await nextTick()
            console.log('tableRowClickHandler refNotesPanel', refRightPanel.value)
            if (refRightPanel.value) {
              await refRightPanel.value.focusTopNoteForm()
            }
          }
        }, 500)
      }
    }

    const fixedRowClickHandler = async () => {
      if (await isMayContinue()) {
        showRightPanelLoader()
        await changeStateParams({selectedItemId: null})
        await nextTick()
        await hideRightPanelLoader()
      }
    }

    const ticketMenuButtonClickHandler = async (event, item) => {
      console.log('Dashboard ticketMenuButtonClickHandler', item.id === ticketMenuParams.value?.id, item.id, ticketMenuParams.value?.id, refTicketMenu.value?.isOpened)
      if (ticketMenuParams.value?.id) {
        store.commit(moduleName + '/updateTicket', {id: ticketMenuParams.value.id, attrs: {isActiveMenu: false}})
      }
      refTicketMenuButton.value = event.target.closest('button')
      await nextTick()
      ticketMenuParams.value = {
        id: item.id,
        name: item.title_orig || item.title,
        color: item.color,
        archived: !!item.archived,
        readonly: !item.isEdit,
      }

      store.commit(moduleName + '/updateTicket', {id: item.id, attrs: {isActiveMenu: true}})

      await triggerHandlerTicketMenu()

    }

    const ticketMenuActionHandler = async (action, ticketId, data: any = {}) => {
      console.log('Dashboard ticketMenuActionHandler', action, ticketId, data)
      refTicketMenu.value.isOpened = false
      switch (action) {
        case 'view':
          await router.push({name: 'Ticket', params: {id: ticketId}})
          break
        case 'movenext':
          autorefreshBlockAdd('Dashboard_ticketMenuAction_movenext')
          try {
            const result = await moveTicketToNextStage(ticketId, moduleName)
            console.log('ticketMenuActionHandler movenext success', result)
            await showResponseSuccessMessage(result)
            if (isSuccessServerResponse(result) && result.xdata?.step) {
              //await doAutorefresh()
              // store.commit(moduleName + '/updateTicket', {id: ticketId, attrs: {
              //   flow_step_id: result.xdata.step.id,
              //   stepName: result.xdata.step.name,
              // }})
              //await store.dispatch('updateTicketGlobally', {id: ticketId, attrs: {...result.xdata.ticket}})
              await store.dispatch('updateTicketGlobally', {id: ticketId, attrs: {
                  ...result.xdata.ticket,
                  flow_step_id: result.xdata.step.id,
                  stepName: result.xdata.step.name,
                  team: result.xdata?.ticket?.team
                }})
              //await store.dispatch('board/update_ticketTeam', {id: ticketId, team: result.xdata?.ticket?.team || []})
              store.commit('board/moveNextStepTicketById', {id: ticketId})
            }
          } catch (e) {
            console.log('ticketMenuActionHandler movenext error', e)
          }
          autorefreshBlockRemove('Dashboard_ticketMenuAction_movenext')
          break
        case 'assignme':
          autorefreshBlockAdd('Dashboard_ticketMenuAction_assignme')
          try {
            const result = await assignTicketToMe(ticketId, moduleName)
            console.log('ticketMenuActionHandler assignme success', result)
            await showResponseSuccessMessage(result)
            if (isSuccessServerResponse(result)) {
              //store.commit(moduleName + '/updateTicket', {id: ticketId, attrs: {team: result.xdata.team || []}})
              store.dispatch('updateTicketGlobally', {id: ticketId, attrs: {team: result.xdata.team || []}})
              //await store.dispatch('board/update_ticketTeam', {id: ticketId, team: result.xdata.team || []})
            }
            // if (result && !result.isError) {
            //   if (result.message) {
            //     await store.dispatch('addNotification', {
            //       text: result.message,
            //     })
            //   }
            //   store.commit(moduleName + '/setTicketTeam', {ticketId, team: result.xdata.team || []})
            //
            // }
          } catch (e) {
            console.log('ticketMenuActionHandler assignme error', e)
          }
          autorefreshBlockRemove('Dashboard_ticketMenuAction_assignme')
          break
        case 'archive':
        case 'restore':
          autorefreshBlockAdd('Dashboard_ticketMenuAction_archive')
          try {
            const result = await archiveTicket(ticketId)
            console.log('ticketMenuActionHandler archive/restore success', result)
            await showResponseSuccessMessage(result)
            if (isSuccessServerResponse(result)) {
              if (props.step !== 'all') {
                store.commit(moduleName + '/removeTicket', ticketId)
              } else {
                //store.commit(moduleName + '/setTicketArchived', {ticketId, archived: action === 'archive'})
                store.commit(moduleName + '/updateTicket', {id: ticketId, attrs: {archived: action === 'archive'}})
              }

              // if (action === 'archive' && store.getters['board/project'] === props.projectId) {
              //   store.dispatch("board/removeTicket", ticketId).then(()=>{
              //     store.commit("board/setArchivedCount", (store.getters['board/getArchivedCount']+1));
              //   })
              // }

            }
            // if (!result.isError) {
            //   if (result.message) {
            //     await store.dispatch('addNotification', {
            //       text: result.message,
            //     })
            //   }
            //   if (props.step !== 'all') {
            //     store.commit(moduleName + '/removeTicket', ticketId)
            //   } else {
            //     store.commit(moduleName + '/setTicketArchived', {ticketId, archived: action === 'archive'})
            //   }
            // }
          } catch (e) {
            console.log('ticketMenuActionHandler archive/restore error', e)
          }
          autorefreshBlockRemove('Dashboard_ticketMenuAction_archive')
          break
        case 'clone':
          await cloneTicket(ticketId)
          break
        case 'delete':
          removeTicketHandler(ticketId, data.name)
          break
        case 'movedToProject':
          console.log('Dashboard movedToProject', ticketId, data)
          // store.commit(moduleName + '/removeTicket', ticketId)
          // await nextTick()
          store.commit(moduleName + '/updateTicket', {id: ticketId, attrs: {flow_id: data.projectId, flowName: data.projectName}})
          await doAutorefresh()
          break
      }
    }

    const removeTicketHandler = async (ticketId, ticketName) => {
      autorefreshBlockAdd('Dashboard_removeTicketHandler')
      try {
        const result = await removeTicket(ticketId, ticketName)
        console.log('Dashboard removeTicketHandler success', result)
        if (result === false) {
          return false
        }
        await showResponseSuccessMessage(result)
        if (isSuccessServerResponse(result)) {
          //store.commit(moduleName + '/removeTicket', ticketId)
          store.dispatch('removeTicketGlobally', ticketId)

        }
      } catch (e) {
        console.log('Dashboard removeTicketHandler error', e)
      }
      autorefreshBlockRemove('Dashboard_removeTicketHandler')
      await changeStateParams({selectedItemId: null})
      return true
    }

    const previewTicketColor = (id, color) => {
      store.commit(moduleName + '/updateTicket', {
        id,
        attrs: {
          color
        }
      })
    }

    const changeTicketColor = async (id, color) => {
      autorefreshBlockAdd('Dashboard_ticketMenuAction_color')
      refTicketMenu.value.isOpened = false
      await nextTick()
      previewTicketColor(id, color)
      try {
        const result = await TicketService.setTicketColor(id, color)
        console.log('DashboardScreen changeTicketColor success', result)
        await showResponseSuccessMessage(result)
        // if (result && !result.isError) {
        //   if (result.message) {
        //     await store.dispatch('addNotification', {
        //       text: result.message,
        //     })
        //   }
        // }
      } catch (e) {
        console.log('DashboardScreen changeTicketColor error', e)
      }
      autorefreshBlockRemove('Dashboard_ticketMenuAction_color')
    }

    const revertTicketColor = (id, data: {initColor: string, lastColor: string}) => {
      previewTicketColor(id, data.initColor)
    }

    const starClickHandler = async (item) => {
      if (!item.isEdit) {
        return false
      }
      autorefreshBlockAdd('Dashboard_ticket_star')
      const star = ((parseInt(item.star, 10) + 1) % 4).toString()
      const ticketId = item.id
      //store.commit(moduleName + '/setTicketStar', {ticketId, star})
      store.commit(moduleName + '/updateTicket', {id: ticketId, attrs: {star}})
      try {
        // const cancelToken = `setTicketStar_${ticketId}`
        // ApiService.cancelRequest(cancelToken);
        // TicketService.setTicketStar(ticketId, star, cancelToken)
        const result = await TicketService.setTicketStar(ticketId, star)
        console.log('DashboardScreen starClickHandler setTicketStar success', result)
        await showResponseSuccessMessage(result, {id: 'setTicketStar'})
      } catch (e) {
        console.log('DashboardScreen starClickHandler setTicketStar error', e)
      }
      autorefreshBlockRemove('Dashboard_ticket_star')
    }

    // const panelDividerDragstartHandler = (evt) => {
    //   console.log('panelDividerDragstartHandler', evt)
    //   evt.dataTransfer.dropEffect = 'move'
    //   evt.dataTransfer.effectAllowed = 'move'
    // }

    const refSelectorColumnsTarget = ref()
    const editColumnsButtonHandler = async () => {
      //refSelectorColumns.value.isOpened = true
      refSelectorColumnsTarget.value = refEditColumnsButton.value
      await triggerHandlerSelectorColumns()
    }

    const headContextmenuHandler = async (event) => {
      event.preventDefault()
      const {clientX, clientY} = event
      const virtualEl = {
        getBoundingClientRect() {
          return {
            width: 0,
            height: 0,
            x: clientX,
            y: clientY,
            top: clientY,
            left: clientX,
            right: clientX,
            bottom: clientY,
          };
        },
      }
      refSelectorColumnsTarget.value = virtualEl //event.target.closest('th')
      isExistSelectorColumns.value = true
      await nextTick()
      refSelectorColumns.value.isOpened = true
      //await triggerHandlerSelectorColumns()
    }

    const toolbarTriggerMenu = async (params) => {
      console.log('Dashboard toolbarTriggerMenu', params)
      if (refSelectorColumns.value) {
        refSelectorColumns.value.isOpened = false
      }
      refScreenMenuTarget.value = params.positionTarget.value
      await triggerHandlerScreenMenu()
    }

    const screenMenuActionHandler = async (action, data: any = {}) => {
      console.log('Dashboard screenMenuActionHandler', action, data)
      if (refScreenMenu.value) {
        refScreenMenu.value.isOpened = false
      }
      let stateParams
      switch (action) {
        case 'show_all':
          stateParams = {requestedById: '0', assignedToId: '0'}
          break
        case 'show_assigned_to_me':
          stateParams = {requestedById: '0', assignedToId: authUserId}
          break
        case 'show_requested_by_me':
          stateParams = {requestedById: authUserId, assignedToId: '0'}
          break
        case 'editColumns':
          refSelectorColumnsTarget.value = refScreenMenuTarget.value
          await triggerHandlerSelectorColumns()
          break
        case 'resetView':
          datatable.resetView()
          break
      }

      if (stateParams) {
        showPanelsLoaders()
        await changeStateParams(stateParams)
        await hidePanelsLoaders()
      }

    }

    const authUser = getAuthUser()
    const authUserId = authUser?.id.toString()

    const screenMenuValueShow = computed(() => {
      //requestedById: '0', assignedToId: '0'
      const requestedById = store.getters['ticketsfilters/requestedById'] || '0'
      const assignedToId = store.getters['ticketsfilters/assignedToId'] || '0'
      let value = ''
      if (requestedById === '0' && assignedToId === '0') {
        value = 'all'
      } else if (requestedById === '0' && assignedToId === authUserId) {
        value = 'assigned_to_me'
      } else if (requestedById === authUserId && assignedToId === '0') {
        value = 'requested_by_me'
      }
      return value
    })


    const isShowTicketId = computed(() => store.getters['settings/show_ticket_id'])
    const getItemTitle = (item) => isShowTicketId.value ? (item.id_html || item.id) + ' - ' + item.title : item.title

    const ticketTasksCountChangeHandler = (data) => {
      //store.commit(moduleName + '/setTicketTasksCount', data)
      const {ticketId, closedCount, totalCount} = data
      store.dispatch('updateTicketGlobally', {
        id: ticketId,
        attrs: {
          todos: parseFloat(totalCount as string) > 0 ? closedCount + '/' + totalCount : null
        }
      })
    }

    // const clickPageHandler = async (event, to) => {
    //   console.log('clickPageHandler', to)
    //   event.preventDefault()
    //   if (await isMayContinue()) {
    //     await router.push(to)
    //   }
    // }

    const gotoTicket = async (id: string) => {
      console.log('gotoTicket', id)
      if (!await isMayContinue()) { return false }

      showPanelsLoaders()
      await changeStateParams({selectedItemId: id})
      await nextTick()
      await hidePanelsLoaders()
      setTimeout(scrollToSelected, 400)
      /*
            store.commit(storeNamespace + '/set_selectedItemId', id)
            const query = {
              ...currentRouteQuery.value,
              ticket: id
            }
            router.push({query})
      */

    }

    const gotoPageHandler = async (event, pageNumber) => {
      console.log('gotoPageHandler', pageNumber, props.pageCurrent)
      event.preventDefault()
      const pageCurrent = props.pageCurrent || 1
      if (pageNumber !== pageCurrent) {
        await gotoPage(pageNumber)
      }
    }

    const gotoPage = async (pageNumber) => {
      console.log('gotoPage', pageNumber, props.selectedItemId)
      if (await isMayContinue()) {
        showLeftPanelLoader()
        if (props.selectedItemId) {
          showRightPanelLoader()
        }
        await nextTick()
        const scrollContainer = document.querySelector('.screen-panel-left-datatable')
        if (scrollContainer) {
          scrollContainer.scrollTop = 0
        }
        await changeStateParams({pageCurrent: pageNumber, selectedItemId: null})
        await nextTick()
        await hidePanelsLoaders()
      }
    }

    const expanderColumnWidth = getExpanderColumnWidth()
    const editColumnsButtonWidth = 20
    const tableWidthChangeHandler = (value) => {
      if (refEditColumnsButton.value) {
        refEditColumnsButton.value.style.left = (value - (editColumnsButtonWidth + (expanderColumnWidth - editColumnsButtonWidth) / 2)) + 'px'
      }
    }

    const checkedItems = ref<string[]>([])
    const checkedItemsObj = computed(() => {
      const obj = {}
      checkedItems.value.forEach(id => {obj[id] = true})
      return obj
    })

    const isAllItemsChecked = computed(() => isMultieditMode.value && checkedItems.value.length === datatableItems.value.length)
    const isMultieditMode = computed(() => checkedItems.value?.length > 0)
    const toggleCheckboxHandler = (event, ticket) => {
      console.log('toggleCheckboxHandler', event, event.target.checked, ticket)
      if (event.target.checked) {
        checkedItems.value.push(ticket.id)
      } else {
        checkedItems.value = checkedItems.value.filter(item => item !== ticket.id)
      }
    }
    const toggleAllCheckboxesHandler = (event) => {
      console.log('toggleCheckboxHandler', event, event.target.checked)
      if (event.target.checked) {
        checkedItems.value = datatableItems.value.map(item => item.id)
      } else {
        cancelMultiEdit()
      }
    }

    const cancelMultiEdit = () => {
      checkedItems.value = []
    }

    const beforeMultiEdit = () => {
      showPanelsLoaders()
    }

    const afterMultiEdit = async () => {
      store.commit(moduleName + '/set_selectedItemId', null)
      cancelMultiEdit()
      await forceRefreshScreen()
      await hidePanelsLoaders()
    }

    const removeSingleMultiEdit = async (ticketId) => {
      const ticket = store.getters[moduleName + '/getItemById'](ticketId)
      if (await removeTicketHandler(ticketId, ticket.title)) {
        await afterMultiEdit()
      }
    }

    watch(() => ([
      //props.projectId,
      props.search,
      props.assignedToId,
      props.requestedById,
      props.searchIn,
      props.selectedItemId,
      props.pageCurrent,
      props.step,
      props.order,
      props.reverse,
      store.getters['ticketsfilters/tagsFilterValues'],
      store.getters['ticketsfilters/tagsLogic'],
    ]), () => {
      console.log('watch cancelMultiEdit')
      cancelMultiEdit()
    })

    return {
      t,
      //resizablePanel,
      isMultiSelectTickets,
      ticketsTableFields,
      ...datatable,
      //...toRefs(loadingsState),
      datatableItems,
      getRouteObj,
      notesPanelLoading,
      fixedRowClickHandler,
      tableRowClickHandler,
      clickDatatableRowHandler,
      changeStateParams,
      refTicketMenu,
      refTicketMenuButton,
      refEditColumnsButton,
      refSelectorColumns,
      refSelectorColumnsTarget,
      isExistTicketMenu,
      isExistSelectorColumns,
      ticketMenuParams,
      tagsAlign,
      stepModel,
      archiveSelectItems,
      // resizePanelsStartHandler,
      // resizePanelsHandler,
      ticketMenuButtonClickHandler,
      ticketMenuActionHandler,
      starClickHandler,
      editColumnsButtonHandler,
      // panelDividerDragstartHandler,
      headContextmenuHandler,
      // refScreenPanelLeft,
      // leftPanelWidth,
      // panelsDividerMoveHandler,
      // panelsDividerMoveEndHandler,
      // getLeftPanelWidth,
      // leftPanelMinWidth,
      // rightPanelMinWidth,
      toolbarTriggerMenu,
      refScreenMenuTarget,
      refScreenMenu,
      isExistScreenMenu,
      screenMenuValueShow,
      screenMenuActionHandler,
      getItemTitle,
      ticketTasksCountChangeHandler,
      previewTicketColor,
      changeTicketColor,
      revertTicketColor,
      navigateWithConfirm,
      gotoTicket,
      gotoPageHandler,
      tableWidthChangeHandler,
      expanderColumnWidth,
      //livePreviewPanelsOnResize,
      toggleCheckboxHandler,
      toggleAllCheckboxesHandler,
      isMultieditMode,
      isAllItemsChecked,
      checkedItems,
      checkedItemsObj,
      cancelMultiEdit,
      beforeMultiEdit,
      afterMultiEdit,
      removeSingleMultiEdit,
      leftPanelScrollContainer,
    }
  }

})

